<template>
  <component :is="layout">
    <div class="fadeInUp" role="main">
      <div class="row fadeInUp" role="main">
        <div class="col-12 col-md-3">
          <ConfigurationNav />
        </div>
        <div class="col-12 col-md-9">
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-header">
              <h5>
                Questionnaires
                <router-link
                  :to="{
                    name: 'config.forms.create',
                  }"
                  class="btn btn-primary btn-sm float-right"
                  >Create questionnaire</router-link
                >
              </h5>
            </div>
            <div class="card-body">
              <div v-if="fieldGroups.length">
                <div v-for="group in fieldGroups" :key="group.id" class="row mb-4">
                  <div class="col-sm-7">
                    <p class="mt-2 mb-0">
                      {{ group.name }}
                    </p>
                  </div>

                  <div class="col-sm-5">
                    <router-link
                      :to="{
                        name: 'config.forms.edit',
                        params: { id: group.id },
                      }"
                      class="btn btn-primary float-right"
                      >Edit</router-link
                    >
                    <router-link
                      :to="{
                        name: 'form.reply',
                        params: { id: group.id },
                      }"
                      class="btn btn-primary float-right mr-3"
                      >View replies</router-link
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <p>
                  You have no questionnaire yet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
export default {
  name: "FormsList",
  components: {
    Default,
    ConfigurationNav,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("questionnaire/config").then((response) => {
      next((vm) => {
        vm.fieldGroups = response.data.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      fieldGroups: [],
      modalOpen: false,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>
