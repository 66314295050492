var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(" Questionnaires "),_c('router-link',{staticClass:"btn btn-primary btn-sm float-right",attrs:{"to":{
                  name: 'config.forms.create',
                }}},[_vm._v("Create questionnaire")])],1)]),_c('div',{staticClass:"card-body"},[(_vm.fieldGroups.length)?_c('div',_vm._l((_vm.fieldGroups),function(group){return _c('div',{key:group.id,staticClass:"row mb-4"},[_c('div',{staticClass:"col-sm-7"},[_c('p',{staticClass:"mt-2 mb-0"},[_vm._v(" "+_vm._s(group.name)+" ")])]),_c('div',{staticClass:"col-sm-5"},[_c('router-link',{staticClass:"btn btn-primary float-right",attrs:{"to":{
                      name: 'config.forms.edit',
                      params: { id: group.id },
                    }}},[_vm._v("Edit")]),_c('router-link',{staticClass:"btn btn-primary float-right mr-3",attrs:{"to":{
                      name: 'form.reply',
                      params: { id: group.id },
                    }}},[_vm._v("View replies")])],1)])}),0):_c('div',[_c('p',[_vm._v(" You have no questionnaire yet. ")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }